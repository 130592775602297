<template>
<div class="container" id="typing">
    <div class="row">
        <p class="center container">
            
            <p class="center flow-white" style=" color:#162a52 !important; text-decoration: underline;margin-top:40px;">
                03 {{ $t('why') }}
            </p>

            
    </div>
</div>    
</template>

<script>
export default {
    name:'Typing',
    
    }

</script>
<style scoped>


   p >a >span {
       color:#162a52 !important;
          
   }

    p >a {
       color:#162a52 !important;
          
   }

   #typing{
  width: 100%;
}
</style>