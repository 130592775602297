<template>
    <div class="parallax-container banner">
       <!-- <LocaleSwitcher/> -->
       
      <router-link to="/" @click.native="$router.go()"><img class="brand-logo-image responsive-img hide-on-small-only" style="max-height: 156px;" src="@/assets/img/logo.png" alt="company-logo"/>
                                <img class="brand-logo-image responsive-img hide-on-med-and-up" style="max-height: 100px;" src="@/assets/img/logo.png" alt="company-logo"/>
      </router-link>
      <br>
      <div class="center container" id="mysection" >    
            
            <p class="flow-white white-text center" style="text-decoration: underline;">01 {{ $t('us') }}</p>
      </div>
       <div class="center container" id="" style="margin-top:100px;">       
            <p class="white-text"> {{ $t('about') }} </p>
            
            <!-- <p class="white-text banner" style="text-align:justify"> {{ $t('more') }}</p>-->
            <p class="white-text banner"> {{ $t('smart') }}</p>
          
      </div>    
      <div class="parallax"><img src='@/assets/img/Optimized-network.jpg'  style="filter: brightness(30%)"></div> 
    </div>
</template>

<script>
import 'materialize-css/dist/css/materialize.css'
import 'materialize-css'
import M from 'materialize-css'
// import LocaleSwitcher from '@/components/LocaleSwitcher'

export default {
  name: 'Home',

  // components: {
  //   LocaleSwitcher
  // },

  data () {
    return {
    }
  }, 
}
document.addEventListener('DOMContentLoaded', function() {
    var elems = document.querySelectorAll('.parallax');
    M.Parallax.init(elems);});

</script>

<style scoped>

.banner{
  padding-top: 80px;
  text-align: center;  
  height: 900px;
}

/* #mysection{
  width: 100%;
} */
</style>


