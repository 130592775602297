<template>
  <section class="section container" id="services" style="">
     <div class="center container"><p class="center flow-white" style=" color:#162a52 !important; text-decoration: underline;margin-top:40px;">02 {{ $t('services') }}</p></div>
<br><br>
    <div class="row">
        <ul class="tabs tabs-fixed-width">
         
          <li class="tab col s6 l2" >
            
            <a href="#inv" class="service center-align" id="myID">
              <i class="material-icons left small" >security</i>
              {{ $t('check') }}</a>
          </li>
          <li class="tab col s6 l2" >
            <a href="#intel" class="service center-align" id="myID">
              <i class="material-icons left small" style="padin-top:10px;" >show_chart</i>
              Intelligence</a>
          </li>
          <li class="tab col s6 l2" >

            <a href="#risk" class="service center-align"  id="myID">
              <i class="material-icons left small" >fingerprint</i>
              {{ $t('risk') }}</a>
          </li>
         
          <li class="tab col s6 l2" >
            <a href="#2" class="service center-align" id="myID">
              <i class="material-icons left small" style="padin-top:10px;" >warning</i>
              {{ $t('monitor') }}</a>
          </li>
        
          
        </ul>


        <div id="inv" class="col s12">
         <h5 class="card-title">{{ $t('inv') }}</h5>
         <br>

              <li>{{ $t ('inv2') }}</li>
              <li>{{ $t ('inv11') }}</li>
              <li>{{ $t ('inv12') }}</li>
              <li>{{ $t ('inv13') }}</li>
              <!-- <li>{{ $t ('inv3') }}</li> -->
              <li>{{ $t ('inv4') }}</li>
              <li>{{ $t ('inv5') }}</li>
              <li>{{ $t ('inv6') }}</li>
              <li>{{ $t ('inv7') }}</li> 
              <li>{{ $t ('inv8') }}</li>
              <li>{{ $t ('inv9') }}</li> 
               <li>{{ $t ('inv14') }}</li>
              <li>{{ $t ('inv10') }}</li>
          
        </div>

        <div id="intel" class="col s12">
            <h5 class="card-title">{{ $t ('intel-p') }}</h5>
             <br>
            <p class="flow-text">{{ $t ('ci-body') }}</p>
            <p class="flow-text">{{ $t ('ci-body2') }}</p>
            <p class="flow-text">{{ $t ('ci-body3') }}</p>
             <ul class="browser-default">
                <li>{{ $t ('intel-p2') }}</li>
                <li>{{ $t ('intel-p3') }}</li>
                 <li>{{ $t ('intel-p6') }}</li>
                <li>{{ $t ('intel-p4') }}</li>
               <li>{{ $t ('intel-p5') }}</li>
            </ul>
             
          
        </div>
        <div id="risk" class="col s12">
            <h5 class="card-title">{{ $t ('risk-p') }} </h5>
              <br>
              <ul class="browser-default">
                <li>{{ $t ('li1') }}</li>
                <li>{{ $t ('li2') }}</li>
                <li>{{ $t ('li3') }}</li>
                <li>{{ $t ('li4') }}</li>
                <li>{{ $t ('li5') }}</li>
                <li>{{ $t ('r1') }}</li>
                <li>{{ $t ('r2') }}</li>
                <li>{{ $t ('r3') }}</li>
               
                <li>{{ $t ('r5') }}</li>
                 <li>{{ $t ('r6') }}</li>
            </ul>         
        </div>

      

        <div id="2" class="col s12">
              <h5 class="card-title ">{{ $t ('surv-h')}}</h5>
              <br>
               <ul class="browser-default">
                  <li>{{ $t ('surv1') }}</li>
                  <br>
                  <li>{{ $t ('surv2') }}</li>
                   <br>
                  <li>{{ $t ('surv3') }}</li>
 
              </ul>
                  
             
          
        </div>
        
      </div>

  </section>
</template>


<script>
import 'materialize-css/dist/css/materialize.css'
import 'materialize-css'
import M from 'materialize-css'
export default {
    name:"Tabs",

}
document.addEventListener('DOMContentLoaded', function() {
    var elems = document.querySelectorAll('.parallax');
    M.Parallax.init(elems);

    var tab_elems = document.querySelectorAll('.tabs');
    M.Tabs.init(tab_elems, {'swipeable':true});

    var tab = document.querySelector(".tabs .indicator");
    tab.style.backgroundColor="#162a52";

    var elem = document.querySelectorAll('.modal');
    M.Modal.init(elem);
    
    }); 

    document.addEventListener('DOMContentLoaded', function() {

});
 
</script>

<style scoped>
#myID > i{
  padding-top: 10px;
}
.tabs .tab a:hover {
  color:#162a52 !important; 
}

.tabs .tab a:focus.active {
  color:white !important;;
  background-color: #162a52 !important; 
}

.service {
  color:#162a52 !important;
  vertical-align: middle !important;
}

#services > div:nth-child(3) > button

 {
  margin-right:15px !important;
  background-color: #162a52 !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #myID {
    font-size: 0px;
  }
  #myID >i{
  font-size: 1.4rem;
  }
 

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
   #myID {
  font-size: 8px !important;
  }
#myID >i{
  
  font-size: 1rem !important;
  }
  i.left{
    margin-right:0px !important;
  }
} 

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #myID {
  font-size: 10px !important;
  }
#myID >i{
  font-size: 1.5rem;
  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #myID {
  font-size: 15px !important;
  }
#myID >i{
  font-size: 2rem !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #myID >i{
  font-size: 1rem !important;
  }
  #myID {
  font-size: 15px !important;
  }
}

</style>