<template>
  <!-- <div class="parallax-container banner">   -->
    <div class="container">
      <!-- <h4 class="center">More About Us</h4> -->
    <ul class="collapsible popout">
    <li>
      <div class="collapsible-header myText"><i class="material-icons left small">school</i>{{ $t('newwhy1') }}</div>
      <div class="collapsible-body myText"><span>{{ $t('newwhy2') }}</span></div>
      <div class="collapsible-body myText"><span>{{ $t('newwhy2a') }}</span></div>
      <div class="collapsible-body myText"><span>{{ $t('newwhy2b') }}</span></div>
    </li>
    <li>
      <div class="collapsible-header myText"><i class="material-icons left small">dashboard</i>{{ $t('newwhy3') }}</div>
      <div class="collapsible-body myText"><span>{{ $t('newwhy4') }}</span></div>
      <div class="collapsible-body myText"><span>{{ $t('newwhy4a') }}</span></div>
      <div class="collapsible-body myText"><span>{{ $t('newwhy4b') }}</span></div>
    </li>
    

    <li>
   <div class="collapsible-header myText"> <i class="material-icons left small">public</i>{{ $t('newwhy7') }}</div>
   <div class="collapsible-body myText"><span>{{ $t('newwhy8') }}</span></div>
    </li>

    <li>
   <div class="collapsible-header myText"> <i class="material-icons left small">group</i>{{ $t('newwhy9') }}</div>
   <div class="collapsible-body myText">{{ $t('newwhy10') }}<span></span></div>
    </li>
    <li>
    <div class="collapsible-header myText"> <i class="material-icons left small">offline_pin</i>{{ $t('newwhy11') }}</div>
   <div class="collapsible-body myText">{{ $t('newwhy12') }}<span></span></div>
    </li>
  </ul>


      
  </div>
  
  
</template>

<script>
import 'materialize-css/dist/css/materialize.css'
import 'materialize-css'
import M from 'materialize-css'

export default {
    name: 'newWhy',
    methods:{
      
  

    }

  
    }

   
    
    document.addEventListener('DOMContentLoaded', function() {
      var elems = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elems);
  });


</script>


<style scoped>
* {box-sizing: border-box}

/* Style the tab */
.tab {
  float: left;

  width: 20%;

}

/* Style the buttons that are used to open the tab content */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #162a52 ;
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: #162a52 !important;
}

/* Style the tab content */
.tabcontent {
  float: left;
  padding: 0px 12px;
  width: 70%;
  border-left: none;
  height: 300px;
}

.myText{
  color:#162a52 !important;
}
</style>