import Vue from 'vue'
import App from './App'
import router from './router'
import {i18n} from './i18n'
import { Trans } from './plugins/Translation'
import FlagIcon from 'vue-flag-icon'
import Meta from 'vue-meta';

Vue.use(Meta);


Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.use(FlagIcon);
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    components: { App },
    template: '<App/>',
    i18n,
    render: h => h(App)
})
// 
