<template>
      <section class="container section scrollspy" id="photos">

      <div class="row">
        <p class="center container"><p class="center flow-white" style=" color:#162a52 !important; text-decoration: underline;margin-top:40px;">04 {{ $t('target') }}</p>
    </div>
    <!-- Manager -->
    <div class="row">
            <div class="col s12 l4">
                <img src="@/assets/img/manager.jpg" alt="" class="responsive-img materialboxed">
            </div>
            <div class="col s12 l6 offset-l1" style="text-align:justify">
              <h5 class="">{{ $t('manager') }}</h5>
              <p class="">{{ $t ('manager-p') }}</p>

              <ul class="browser-default" style="text-align:justify">
                <li>{{ $t ('m-b') }} </li>
                <li>{{ $t ('m-b2') }} </li>
                 <li>{{ $t ('m-b3') }} </li>
                <li>{{ $t ('m-b4') }} </li>
                 <li>{{ $t ('m-b5') }} </li>
                <li>{{ $t ('m-b6') }} </li>
                 <li>{{ $t ('m-b7') }} </li>
               
              </ul> 
            </div>
          </div>

    <div class="row">
            <div class="col s12 l4 offset-l1 push-l7">
                <img src="@/assets/img/hr.jpg" alt="" class="responsive-img materialboxed">
            </div>
            <div class="col s12 l6 offset-l1 pull-l5 right-align">
             
              <h5 class="">{{ $t('lay') }}</h5>
                <br>
               <ul class="browser-default" style="text-align:justify">
              <li style="text-align:justify">{{ $t('lay2') }} </li>
              <br>
              <li style="text-align:justify">{{ $t('hr') }} </li>
              <br>
              <li style="text-align:justify">{{ $t('hr2') }} </li>
              <br>
              <li style="text-align:justify">{{ $t('hr3') }} </li>
               </ul>
            </div>
          </div>
      <div class="row">
      <div class="col s12 l4">
          <img src="@/assets/img/sale.jpg" alt="" class="responsive-img materialboxed">
      </div>
      <div class="col s12 l6 offset-l1" style="text-align:justify">
        
        <h5 class="">{{ $t('sales') }}</h5>
        <br>
        <ul class="browser-default" style="text-align:justify">
            <li style="text-align:justify">{{ $t('s') }} </li>
            <br>
             <li style="text-align:justify">{{ $t('s1') }} </li>
             <br>
              <li style="text-align:justify">{{ $t('s2') }} </li>
             
        </ul>
        
      </div>
    </div>
    <div class="row">
            <div class="col s12 l4 offset-l1 push-l7" style="text-align:justify">
                <img src="@/assets/img/marketing.jpg" alt="" class="responsive-img materialboxed">
            </div>
            <div class="col s12 l6 offset-l1 pull-l5 right-align">
              <h5 class="">{{ $t('lay3') }} </h5>
              <br>
             
              <ul class="browser-default" style="text-align:justify">
            <li style="text-align:justify">{{ $t('a3') }} </li>
            <br>
             <li style="text-align:justify">{{ $t('a') }} </li>
             <br>
              <li style="text-align:justify">{{ $t('a2') }} </li>
             
        </ul>     
            </div>
    </div>

    <div class="row">
          <div class="col s12 l4">
              <img src="@/assets/img/procurement.jpg" alt="" class="responsive-img materialboxed">
          </div>
          <div class="col s12 l6 offset-l1" style="text-align:justify">
            <h5 class="">{{ $t('procurement') }}</h5>
            <br>
             <ul class="browser-default" style="text-align:justify">
               <li style="text-align:justify">{{ $t('a4') }} </li>
               <br>
             <li style="text-align:justify">{{ $t('a5') }} </li>
             <br>
              <li style="text-align:justify">{{ $t('a6') }} </li>
              <br>
              <li style="text-align:justify">{{ $t('a7') }} </li>
             </ul>
           
          </div>
        </div>

    <div class="row">
      <div class="col s12 l4 offset-l1 push-l7" style="text-align:justify">
          <img src="@/assets/img/law.jpg" alt="" class="responsive-img materialboxed">
      </div>
      <div class="col s12 l6 offset-l1 pull-l5 right-align">
        <h5 class="">{{ $t('legal') }} </h5>
         <br>
             <ul class="browser-default" style="text-align:justify">
               <li style="text-align:justify">{{ $t('l1') }} </li>
               <br>
             <li style="text-align:justify">{{ $t('l2') }} </li>
             <br>
              <li style="text-align:justify">{{ $t('l3') }} </li>
              <br>
              <li style="text-align:justify">{{ $t('l4') }} </li>
              <br>
                <li style="text-align:justify">{{ $t('l5') }} </li>
             </ul>     
      </div>
    </div>
  
          <div class="row">
            <div class="col s12 l4 offset-l1 push-l7" style="text-align:justify">
                <img src="@/assets/img/av.jpg" alt="" class="responsive-img materialboxed">
            </div>
            <div class="col s12 l6 offset-l1 pull-l5 right-align">
              <h5 class="">{{ $t('lowyer') }} </h5>
              <br>
              
               <ul class="browser-default" style="text-align:justify">
                              <li>{{ $t('av1') }}</li>
                              <br>
                              <li>{{ $t('av2') }}</li>
                              <br>
                              <li>{{ $t('av3') }}</li>

                          </ul>

            </div>
          </div>
          <div class="row">
                  <div class="col s12 l4" >
                      <img src="@/assets/img/insurance.jpg" alt="" class="responsive-img materialboxed">
                  </div>
                  <div class="col s12 l6 offset-l1">
                    <h5 class="">{{ $t('insurance') }}</h5>        
                    <p>{{ $t('ins') }}
                        <ul class="browser-default">
                            <li>{{ $t('ins2') }}</li>
                            <br>
                            <li>{{ $t('ins3') }}</li>
                            <br>
                            <li>{{ $t('ins4') }}</li>
                          
                            <!-- <li>{{ $t('lay12') }}</li> -->
                        </ul>
                      </p>
                    
                  </div>
          </div>
                
            <div class="row">
                  <div class="col s12 l4 offset-l1 push-l7" style="text-align:justify">
                      <img src="@/assets/img/pf.jpg" alt="" class="responsive-img materialboxed">
                  </div>
                  <div class="col s12 l6 offset-l1 pull-l5 right-align">
                    <h5 class="">{{ $t('lay14') }} </h5>
                    <p style="text-align:justify"> 
                      <ul class="browser-default">
                                    <li>{{ $t('lay15') }}</li>
                                    <br>
                                    <li>{{ $t('lay16') }}</li>
                                    <br>
                                    <li>{{ $t('lay17') }}</li>
                                    <br>
                                    <li> {{ $t('lay18') }}</li>
                                    <br>
                                    <li>{{ $t('lay19') }}</li>
                                    <!-- <li>{{ $t('lay12') }}</li> -->
                      </ul>
                    </p>      
                  </div>

                  
      </div>
        <div class="row">
                  <div class="col s12 l4" >
                      <img src="@/assets/img/banks.jpg" alt="" class="responsive-img materialboxed">
                  </div>
                  <div class="col s12 l6 offset-l1">
                    <h5 class="">{{ $t('banks') }}</h5> 
                    <br>       
                    <p>{{ $t('banks2') }}
                        <ul class="browser-default">
                            <li>{{ $t('banks3') }}</li>
                            <br>
                            <li>{{ $t('banks4') }}</li>
                        </ul>
                      </p>
                    
                  </div>
          </div>
  
  </section>
</template>


<script>
export default {

    name: "Layers",

    data(){
        return{

        }
    },
    

}
</script>

<style scoped>
h5 {
    color:#162a52 !important; 
}

/* #photos{
  width: 100%;
} */
</style>