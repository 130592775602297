<template>    
  <footer class="page-footer fixed">
    <!-- <div class="container"> -->
      <cookie-law theme="dark-lime"></cookie-law>
      <!-- <cookie-law v-on:accept="ThankYouMethod()"/> -->
      <!-- <cookie-law>
            <div slot-scope="props">
              <button class="skew" @click="props.accept"><span>I accept</span></button>
              <p>
                This site uses 🍪
              </p>
              <button class="skew" @click="props.close"><span>Ignore me</span></button>
            </div>
 
</cookie-law> -->
      <div class="row">
        
          <div class="col l6 s12 white-text text-lighten-1 center"> 
              <ul>
                <li class="center">ROMANIA</li>
                <li><img class="responsive-img center" src="../assets/img/romanian-flag.png" style="max-height: 35px;"></li>
                <li class="center">{{ $t('buc') }}</li>
                <li class="center">Slatina</li>
                <li class="center">Craiova</li>
                <li class="center">Ramnicu Valcea</li>                                                     
              </ul>
                  <span style="font-size: 28px; padding-right:10px;">             
                  <a class="large" href="https://www.facebook.com/SMARTINVESTIGATIONS/" target="_blank"><i class="fab fa-facebook"></i></a> 
              </span>   
                    
              <span style="font-size: 28px;">
                  <a class="large" href="https://www.linkedin.com/company/37480126/" target="_blank"><i class="fab fa-linkedin"></i></a>        
              </span>                              
          </div> 

          <div class="col l6 s12 center white-text text-lighten-1">
            
              <a href="/" ><img class="brand-logo-image responsive-img hide-on-small-only" style="max-height: 156px;" src="@/assets/img/logo.png" alt="company-logo"/>
                                <img class="brand-logo-image responsive-img hide-on-med-and-up" style="max-height: 100px;" src="@/assets/img/logo.png" alt="company-logo"/>
              </a>
              
              <p class="center">{{ $t('cc') }}</p> 
          </div>       
      </div>           
    <!-- </div>        -->
  </footer>  
</template>
<script>
import CookieLaw from 'vue-cookie-law'
export default {
    name: 'Footer',  
    components: { CookieLaw }
}
</script>

<style scoped>
#app > footer{
    background-color:#162a52 !important; 
    /* border:solid;   */
}

a > i{
  color:white;
}


/* 4. Reports page */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #myH5 {
    text-align: center !important;
  }
}




</style>