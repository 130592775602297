<template>
<div class="parallax-container rap-banner2">
  <br><br>
    <div class="col s12 center">
            <h5 class="white-text" style="margin-top: 40px;">{{ $t('rep') }} </h5>
    </div>
  <!-- Modal Trigger -->
<div class="container center" style="margin-top: 40px;">
            <div class="row">
                
                <button data-target="modal2" class="btn modal-trigger" style="">{{ $t ('btn2') }}</button>
                <!-- <button data-target="modal6" class="btn modal-trigger" style="">{{ $t ('btn8') }}</button> -->
                
             </div>

             <div class="row">
                <!-- <button data-target="modal8" class="btn modal-trigger" style="">{{ $t ('btn8') }}</button> -->
                
                <!-- <button data-target="modal4" class="btn modal-trigger" style="">{{ $t ('btn4') }}</button> -->
                
            </div>

            <div class="row">
                  <button data-target="modal5" class="btn modal-trigger" style="">{{ $t ('btn5') }}</button>
                 
                <button data-target="modal1" class="btn modal-trigger" style="">{{ $t ('btn') }}</button>
                
           </div>

           <div class="row">
                <button data-target="modal7" class="btn modal-trigger" style="">{{ $t ('btn7') }}</button>
                
                <button data-target="modal3" class="btn modal-trigger" style="">{{ $t ('btn3') }}</button>
                
         
          </div>
      <!-- </ul>  -->
      <div class="row">
        <h5 class="white-text" style="margin-top: 80px;">{{ $t ('para') }}</h5>
        <br>
        <p class="white-text">{{ $t ('para2') }}</p>
      </div>
         
</div>
                 <!-- Modal Structure -->
        <div id="modal7" class="modal">
              <div class="modal-content">
                    <h5 class="card-title">{{ $t('btn7') }}</h5>
                    <br>
                     <p class="">{{ $t ('f') }}</p>
                    <p class="">{{ $t ('f2') }}</p>
                      <p class="">{{ $t ('f3') }}</p>
                       <p class="">{{ $t ('f4') }}</p>
                           <p class="">{{ $t ('f5') }}</p>
                    <p class="">{{ $t ('f6') }}</p>
                  

              </div>
              <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
              </div>
        </div>

        <!-- Modal Structure -->
        <div id="modal1" class="modal">
              <div class="modal-content">
                    <h5 class="card-title">{{ $t('inv') }}</h5>
                    <br>
                     <p class="">{{ $t ('i') }}</p>
                    <p class="">{{ $t ('i2') }}</p>
                      <p class="">{{ $t ('i3') }}</p>
                       <p class="">{{ $t ('i4') }}</p>

              </div>
              <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
              </div>
        </div>
        <!-- IT -->
        
        <!-- Modal Structure -->
        <div id="modal2" class="modal">
              <div class="modal-content">
                <h5 class="flow-text">{{ $t ('intel-p') }}</h5>
              <br>
              <p class="">{{ $t ('ci-body') }}</p>
              <p class="">{{ $t ('ci-body2') }}</p>
              <p class="">{{ $t ('ci-body3') }}</p>
              <ul class="browser-default">
                  <li>{{ $t ('intel-p2') }}</li>
                  <li>{{ $t ('intel-p3') }}</li>
                  <li>{{ $t ('intel-p6') }}</li>
                  <li>{{ $t ('intel-p4') }}</li>
                <li>{{ $t ('intel-p5') }}</li>
              </ul>

              </div>
              <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
              </div>
              </div>
              <!-- OSINT -->
              
        <!-- Modal Structure -->
        <div id="modal3" class="modal">
              <div class="modal-content">
                <h5 class="card-title ">{{ $t ('surv-h')}}</h5>
               <br>
                <p class="">{{ $t ('supra') }}</p>
                <p class="">{{ $t ('supra2') }}</p>
                <p class="">{{ $t ('supra3') }}</p>
                <p class="">{{ $t ('supra4') }}</p>
                <p class="">{{ $t ('supra5') }}</p>
               

              </div>
              <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
              </div>
        </div>

            <!-- risk -->
          <div id="modal5" class="modal">
            <div class="modal-content">
            <h5 class="flow-text">{{ $t ('man-h') }} </h5>
            <br>
            <p>{{ $t ('man')}}</p>
            <p>{{ $t ('man2')}}</p>
             <p>{{ $t ('man3')}}</p>
            <p>{{ $t ('man4')}}</p>
         
          


          </div>
                  <div class="modal-footer">
                    <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
                  </div>
            </div>

            <!-- OSINT -->
            <!-- <div id="modal6" class="modal">
            <div class="modal-content">
            <h5 class="card-title ">{{ $t ('osint-h')}}</h5>
              <br> -->
             <!-- <p class="card-title ">{{ $t ('osint-h')}}</p> -->
              <!-- <br>
                <ul class="browser-default">
                      <li>{{ $t ('map') }}</li>
                       <p>{{ $t ('map-body') }}</p>
                      <li>{{ $t ('social') }}</li>
                        <p>{{ $t ('social-body') }}</p>
                      <li>{{ $t ('iot-h') }}</li>
                        <p>{{ $t ('iot') }}</p>
                      <li>{{ $t ('rr') }}</li>
                      <p>{{ $t ('rr-body') }}</p>
                                                      
              </ul>
            </div>
            <div class="modal-footer">
                    <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
                  </div>
            </div> -->

          <!-- TSCM -->
          <!-- <div id="modal8" class="modal">
            <div class="modal-content">
            <h5 class="card-title center">{{ $t('tscm-p') }} </h5>
            <br> -->
                <!-- <ul class="browser-default"> -->

                                <!-- <p class="flow-text">{{ $t ('tscm-p') }}</p> -->
                <!-- <p style="text-align:justify"> {{ $t ('ts2') }}</p>

                <p style="text-align:justify"> {{ $t ('ts3') }} </p>
                 <p style="text-align:justify"> {{ $t ('ts4') }} </p>
                 
            </div>
            <div class="modal-footer">
                    <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
                  </div>
            </div> -->

    <div class="parallax"><img src='@/assets/img/Optimized-mix.jpg' style="filter: brightness(30%)"></div>
  </div>
    
</template>

<script>
import 'materialize-css/dist/css/materialize.css'
import 'materialize-css'
import M from 'materialize-css'
export default {
  name:"newReports",
  
    
}


  document.addEventListener('DOMContentLoaded', function() {
    var elems = document.querySelectorAll('.parallax');
    M.Parallax.init(elems);});
  </script>

<style scoped>

.btn{
  background-color: inherit;
}

/* 4. Reports page */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .rap-banner2 {
    height: 60rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .rap-banner2 {
     height: 60rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .rap-banner2 {
    height: 60em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .rap-banner2 {
    height: 40%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .rap-banner2 {
    height:40rem;
  }
 

}
</style>