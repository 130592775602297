<template>
    <div>
        <Home/>  
        <tabs3/>        
         <Typing />  
        <!-- <roTyping v-else/>  -->
        <newWhy/> 
        <Layers/>                  
          <newReports/> 
        <Partners/>                 
    </div>    
</template>


<script>
import Home from '@/components/indexComponents/Home'
import Typing from '@/components/indexComponents/Typing'
// import roTyping from '@/components/indexComponents/roTyping'
import Partners from '@/components/indexComponents/Partners'
import tabs3 from '@/components/indexComponents/tabs3'
import newWhy from '@/components/indexComponents/newWhy'
import newReports from '@/components/indexComponents/newReports'
import Layers from '@/components/indexComponents/Layers'
export default {
    name: 'Index',
    data(){
      return {}
    },
  components: {
    Home,    
    Typing,
    tabs3,
    // roTyping,
    newWhy ,
    Partners,
    Layers, 
    newReports
  },   

}
</script>

<style scoped>


</style>