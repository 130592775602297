<template>

<section class=""  id="partners">
<div class="row">
<p class="center flow-white" style=" color:#162a52 !important;text-decoration: underline;margin-top:40px;">05 {{ $t('trusted-by') }}</p>
</div>

            <div class="container center">
              
              <div class="row">
              <!-- <h5>{{ $t('trusted') }}</h5> -->
              </div>     
                <div class="row"> 
                  <div class="col s12 l6">   
                      <a href="https://patronatul-detectivilor.ro"><img class="responsive-img" src="@/assets/img/logopdpr.png" alt="PDPR" style="max-height: 250px;"></a>
                      <h5 class="center">{{ $t('pdpr') }}</h5>
                      <p class="center">{{ $t('pdpr-info') }}</p>
                  </div>
                  <div class="col s12 l6"> 
                      <a href="https://wad.net"><img class="responsive-img" src="@/assets/img/wad_new.jpg" alt="WAD" style="max-height: 250px;"></a>
                      <h5 class="center">{{ $t('wad') }}</h5> 
                      <p class="center">{{ $t('wad-info') }}</p>    
                </div>
            </div>
            </div>
        </section>
</template>
<script>
export default {
    name: "Partners"   
}
</script>
<style scoped>
  h3{
    color:#162a52 !important; 
  }
h5{
    color:#162a52 !important; 
  }

  /* #partners{

  width: 100%;
} */

</style>