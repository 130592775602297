<template>
    <div id="app">
      <!-- <LocaleSwitcher /> -->
      <!-- <MainMenu /> -->
        <!-- <router-view/> -->
        <Index />
        <Footer/>
    </div>
</template>

<script>


import Footer from '@/components/Footer'
// import LocaleSwitcher from '@/components/LocaleSwitcher'
// import MainMenu from '@/components/MainMenu'
import Index from '@/components/Index'
// let ROOT_PATH = 'https://smartinvestigations.ro'
export default {
    name: 'App',
    components: {
      Footer,
      // LocaleSwitcher,
      Index,
      // MainMenu,
  },
  // data() {
  //   return {
  //     logo: require('@/assets/img/logo.png')
  //   }
  // },
  // metaInfo() {
  //   return {
  //     meta: [
  //         // Twitter Card
  //         {name: 'twitter:card', content: 'Private Investigators'},
  //         {name: 'twitter:title', content: 'Smart Investigations'},
  //         {name: 'twitter:url', content: "https://smartinvestigations.ro"},
        
  //         {name: 'twitter:description', content: 'An interdisciplinary team of experts in gathering information from online and from the field.'},
  //         // image must be an absolute path
  //         {name: 'twitter:image', content: this.logo},
  //         // Facebook OpenGraph
  //         {property: 'og:title', content: 'Smart Investigations'},
  //         {property: 'og:site_name', content: 'Private Investigators'},
  //         {property: 'og:type', content: 'website'},
  //         {property: 'og:image', content:  this.logo},
  //         {property: 'og:description', content: 'An interdisciplinary team of experts in gathering information from online and from the field.'},
  //         {property: 'og:url', content: 'https://smartinvestigations.ro'},
  //         {property: "fb:app_id", content: '935596110258546'}
      
      
  //       ]
  //   }
  // }
}




</script>
<style>
  #app {
      font-family: 'Source Code Pro', Verdana, Lucida Console, Courier, sans-serif; 
      /* font-family: Arial, Helvetica, sans-serif; */
      color: #2c3e50;    
  }

 /* html, body {
  /* height: 100%; */
/* } */ 

#app {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}
</style>

